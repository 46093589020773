import SearchIcon from "@mui/icons-material/Search";
import { Box, InputAdornment, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

export const HeaderSearchBox = memo(() => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [inputValue, setInputValue] = useState("");

	useEffect(() => {
		const query = searchParams.get("query");
		setInputValue(query ?? "");
	}, [searchParams]);

	const debouncedFunction = useMemo(
		() =>
			debounce((value: string) => {
				searchParams.set("query", value);
				value
					? navigate(`/search?${searchParams.toString()}`, {
							replace: value.length ? true : false,
					  })
					: navigate("/");
			}, 300),
		[navigate, searchParams]
	);

	const debouncedNavigate = useCallback(
		(value: string) => debouncedFunction(value),
		[debouncedFunction]
	);

	const onTextChange = (value: string) => {
		setInputValue(value);
		debouncedNavigate(value);
	};

	return (
		<Box
			sx={{
				zIndex: 1400,
				alignSelf: "center",
				width: { xs: "174px", sm: "228px", md: "258px" },
			}}
		>
			<TextField
				placeholder="Search"
				focused={false}
				InputProps={{
					startAdornment: (
						<InputAdornment
							position="start"
							sx={{
								color: "white",
								display: { xs: "none", sm: "flex" },
							}}
						>
							<SearchIcon />
						</InputAdornment>
					),
				}}
				value={inputValue}
				multiline={false}
				autoFocus
				sx={{
					background: "transparent",
					borderRadius: 3,
					width: "inherit",
					"& .MuiInputBase-input": {
						paddingTop: "4px",
						paddingBottom: "4px",
						color: "white",
						fontWeight: 500,
					},
					"& .MuiOutlinedInput-notchedOutline": {
						borderColor: "#fe0039",
					},
					"&:hover .MuiOutlinedInput-root": {
						"& .MuiOutlinedInput-notchedOutline": {
							borderColor: "#fe003970",
						},
					},
				}}
				onChange={(event) => onTextChange(event.target.value)}
			/>
		</Box>
	);
});
